import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import ConfirmButton from "../common/ConfirmButton";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";

const apiUrl = Config.apiUrl;
const PaymentMethodManager = (props) => {
    const [, setLoading] = useRecoilState(loadingAtom);

    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');

    const newEntity = {
        id: undefined,
        name: '',
        allowSplit: false

    };
    const [formData, setFormData] = useState(newEntity);


    async function makeQuery() {
        if (id) {
            try {
                const url = apiUrl + '/api/paymentMethods/id=' + id;
                const response = await makeRequest(url, 'GET', {'Useruuid':Cookies.get('userUUID')});
                setFormData(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            setFormData(newEntity);
        }

    }

    useEffect(() => {
        makeQuery();
    }, [id]);

    function validate() {

        if (!formData.name || formData.name === '') {
            alert(`Informe o nome.`);
            return false;
        }

        return true;
    }


    const onConfirm = async () => {
        if (!validate()) {
            return;
        }
        try {
            const url = apiUrl + '/api/paymentMethods';
            await makeRequest(url, id ? 'PUT' : 'POST', {
                'Content-Type': 'application/json',
                'Useruuid':Cookies.get('userUUID')
            }, JSON.stringify(formData));
            setFormData(newEntity);
            navigate('/paymentmethodlist');
        } catch (error) {
            console.error('Error adding paymentMethod:', error);
        }
    };

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    return (
        <div>
            <div className="header-label">{id > 0 ? 'Editar' : 'Cadastrar'} Forma de Pagamento</div>

            {formData.id && (
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Código:</div>
                    <input type="text" style={{backgroundColor: '#CCCCCC'}} value={formData.id} disabled={true}/>
                </div>)}

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Nome:</div>
                <input type="text" value={formData.name}
                       name="name"
                       onChange={handleChange}/>
            </div>

            <div style={{marginTop: '5px', alignItems: 'center', display: 'flex'}}>
                <div>Permite parcelar?</div>
                <input type="checkbox" checked={formData.allowSplit}
                       name="allowSplit"
                       onChange={handleChange}/>
            </div>

            <ActionButtonsManagerView>
                <ConfirmButton onClick={onConfirm}/>
            </ActionButtonsManagerView>
        </div>
    );
};

export default PaymentMethodManager;
