import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import Config from "../../core/config";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import ConfirmButton from "../common/ConfirmButton";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";

const apiUrl = Config.apiUrl;

const SectorManager = () => {
    const [, setLoading] = useRecoilState(loadingAtom);
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');

    const newEntity = {
        id: undefined,
        name: '',
    };

    const [formData, setFormData] = useState(newEntity);

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    async function makeQuery() {
        if (id) {
            try {
                const url = apiUrl + '/api/sectors/id=' + id;
                const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
                setFormData(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            setFormData(newEntity);
        }
    }

    useEffect(() => {
        makeQuery();
    }, [id]);

    function clearEntity() {
        setFormData(newEntity);
    }

    const onConfirm = async () => {

        try {
            if (validateInputs()) {
                setLoading(true);
                await new Promise(resolve => setTimeout(resolve, 300));
                await saveEntity();
                navigate('/sectorlist');
            }
        } catch (error) {
            console.error('Error saving:', error);
            alert('Error ao salvar.');
        } finally {
            setLoading(false);
        }
    };

    function validateInputs() {

        if (!formData.name || formData.name === '') {
            alert('Preencha o campo nome.');
            return false;
        }

        return true;
    }

    async function saveEntity() {
        const userUUID = Cookies.get('userUUID');
        const url = apiUrl + '/api/sectors';
        await makeRequest(url, id ? 'PUT' : 'POST', {
            'Content-Type': 'application/json',
            'Useruuid': userUUID,
        }, JSON.stringify(formData));
    }

    return (
        <div>
            <div className="header-label">{formData.id > 0 ? 'Editar' : 'Cadastrar'} Setor</div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Nome:</div>
                <input type="text" style={{width: '300px'}} value={formData.name} name="name"
                       onChange={handleChange}/>
            </div>


            <ActionButtonsManagerView>
                <ConfirmButton onClick={onConfirm}/>
            </ActionButtonsManagerView>


        </div>
    );
};

export default SectorManager;
