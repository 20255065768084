import React, {useState, useEffect} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import DatePicker from "react-datepicker";
import {
    adjustDateToBrowserTimeZone, calculateDaysDifference,
    formatDateTimeToString,
    formatDateToAmericanFormat,
    formatDateToString, getOnlyDate, renderSaleOptions, SaleStatus, statusOptions, statusTranslate
} from "../../utils/utils";
import {findSectorsByQueryString, getSaleById} from "../../utils/requestUtils";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';

const apiUrl = Config.apiUrl;
const SaleList = (props) => {
    const {onEditButtonClick, onNewButtonClick} = props;
    const [sales, setSales] = useState([]);
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [status, setStatus] = useState('ALL');
    const [totalValue, setTotalValue] = useState(0);
    const navigate = useNavigate();
    const [sectors, setSectors] = useState([]);
    const [selectedSectorId, setSelectedSectorId] = useState(-1);

    async function makeQuery() {
        try {

            const newStartDate = startDate ? startDate : new Date(1900, 0, 1);
            const newEndDate = endDate ? endDate : new Date(2100, 0, 1);
            const url = `${apiUrl}/api/sales/queryByDateAndStatusAndSector?startDate=${formatDateToAmericanFormat(newStartDate)}&endDate=${formatDateToAmericanFormat(newEndDate)}&status=${status}&sectorId=${selectedSectorId}`;
            const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
            setSales(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {

        }
    }

    useEffect(() => {
        findSectorsByQueryString("").then(sectors => setSectors(sectors));
        makeQuery();
    }, []); // Empty dependency array ensures that useEffect runs only once, similar to componentDidMount

    useEffect(() => {
        setTotalValue(sales.reduce((acc, obj) => acc + obj.totalValue, 0));
    }, [sales]);

    async function removeSale(id) {
        try {
            const url = apiUrl + '/api/sales/' + id;
            await makeRequest(url, 'DELETE', {'Useruuid': Cookies.get('userUUID')});
            await makeQuery();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleQuery = async (e) => {
        await makeQuery();
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };
    const onPayClick = async (id) => {
        const sale = await getSaleById(id);
        if (!window.confirm(`Deseja realmente ${!sale.paymentDate ? "Baixar" : "Estornar"}?`)) {
            return;
        }
        if (sale.paymentDate) {
            sale.paymentDate = undefined;
            sale.status = 0;
        } else {
            sale.paymentDate = getOnlyDate(new Date());
            sale.status = 1;
        }

        const url = apiUrl + '/api/sales';
        await makeRequest(url, 'PUT', {
            'Content-Type': 'application/json',
            'Useruuid': Cookies.get('userUUID')
        }, JSON.stringify(sale));

        await makeQuery();
    };

    function getBackgroundColor(sale) {
        const currentDate = getOnlyDate(new Date());
        const dueDate = getOnlyDate(adjustDateToBrowserTimeZone(new Date(sale.dueDate)));
        const errorColor = 'var(--error-color)';
        const warningColor = 'var(--warning-color)';
        const normalColor = 'var(--normal-color)';
        if (sale.status === 0) {
            if (dueDate < currentDate)
                return errorColor;
            if (calculateDaysDifference(dueDate, currentDate) === 0) {
                return warningColor;
            }
        }
        return normalColor;
    }

    return (
        <>
            <div style={{display:"flex", alignItems:"center", gap: '10px'}}>
                <div className="header-label">Consulta de Serviços</div>
                <button className={"new-button"} onClick={() => navigate('/sale')}>Novo</button>
            </div>
            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%'}}>

                <div style={{marginTop: '5px', alignItems: 'center', display: "flex"}}>
                    <div style={{flexDirection: "column", alignItems: 'center'}}>

                        Data:
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <DatePicker
                                className="datepicker"
                                selected={startDate}
                                onChange={handleStartDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />

                            até
                            <DatePicker
                                className="datepicker"
                                selected={endDate}
                                onChange={handleEndDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />
                        </div>
                    </div>
                    <div style={{flexDirection: "column", alignItems: 'center'}}>

                        Setor
                        <div style={{display: 'flex', alignItems: 'center'}}>

                            <select value={selectedSectorId}
                                    onChange={(e) => setSelectedSectorId(e.target.value)}>
                                <option value="-1">Todos</option>
                                {sectors.map((sector, index) => (
                                    <option key={index} value={sector.id}>
                                        {sector.name}
                                    </option>
                                ))}
                            </select>

                        </div>
                    </div>
                    <div style={{flexDirection: "column", alignItems: 'center'}}>

                        Status
                        <div style={{display: 'flex', alignItems: 'center'}}>

                            <select value={status} onChange={(e) => setStatus(e.target.value)}>
                                <option key="ALL" value="ALL">
                                    {statusTranslate.ALL}
                                </option>
                                {renderSaleOptions()}
                            </select>

                            <button onClick={handleQuery}>Consultar</button>
                        </div>
                    </div>

                </div>

            </div>

            <table style={{width: '100%', height: '420px'}}>

                <tr>
                    <th>
                        Código
                    </th>
                    <th>
                        Cliente
                    </th>
                    <th>
                        Descrição
                    </th>
                    <th>
                        Setor
                    </th>
                    <th>
                        Data
                    </th>
                    <th>
                        Valor
                    </th>
                    <th>
                        Status
                    </th>
                    <th>
                        Entrega
                    </th>
                    <th/>
                    <th/>
                    <th/>
                </tr>
                <tbody style={{overflowY: 'auto'}}>
                {sales.map((sale, index) => (


                    <tr style={{backgroundColor: getBackgroundColor(sale)}}>
                        <td>
                            <div key={index}>{sale.code}</div>
                        </td>
                        <td>
                            <div key={index}>{sale.customerName}</div>
                        </td>
                        <td>
                            <div key={index} style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '250px'
                            }}>{sale.description}</div>
                        </td>
                        <td>
                            <div key={index}>{sale.sector.name}</div>
                        </td>
                        <td>
                            <div key={index}>{formatDateToString(new Date(sale.saleDate))}</div>
                        </td>
                        <td>
                            <div
                                key={index} style={{textAlign: 'right'}}>{sale.totalValue.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</div>
                        </td>
                        <td>
                            <div key={index}>{statusTranslate[sale.status]}</div>
                        </td>
                        <td>
                            <div key={index}>{formatDateTimeToString(new Date(sale.deliveryDate))}</div>
                        </td>
                        <td>
                            <button onClick={async () => {
                                await navigate('/sale?id=' + sale.id);
                            }}>Editar
                            </button>
                        </td>
                        {/*<td>*/}
                        {/*    <button className="finish-button" onClick={async () => {*/}
                        {/*        await onPayClick(sale.id);*/}
                        {/*    }}>*/}
                        {/*        {!sale.paymentDate ? "Baixar" : "Estornar"}*/}
                        {/*    </button>*/}
                        {/*</td>*/}
                        {/*<td>*/}
                        {/*    <button className="remove-button" onClick={async () => {*/}
                        {/*        // eslint-disable-next-line no-restricted-globals*/}
                        {/*        if (window.confirm('Deseja realmente excluir?')) {*/}
                        {/*            await removeSale(sale.id);*/}
                        {/*        }*/}
                        {/*    }}>Excluir*/}
                        {/*    </button>*/}
                        {/*</td>*/}

                    </tr>
                ))}
                </tbody>
                {sales.length === 0 && (<tfoot>Nenhum item encontrado.</tfoot>)}
            </table>
            <div style={{marginTop: '5px', alignItems: 'center'}}>

                <div className="total-label">Valor Total: R$ {totalValue.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}</div>
            </div>

        </>
    );
};

export default SaleList;
