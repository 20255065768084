import React, {useState, useEffect} from 'react';
import '../../css/Select.css';
import {getAllPaymentMethods, getProductAvailabilityPerDate} from "../../utils/requestUtils";
import ModalDiv from "../../ModalDiv";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import ConfirmButton from "../common/ConfirmButton";
import DatePicker from "react-datepicker";
import {
    adjustDateToBrowserTimeZone,
    adjustDateToServerTimeZone,
    calculateDaysDifference,
    getOnlyDate
} from "../../utils/utils";

const PaymentMethodValueSelect = (props) => {
    const {onClose, onConfirm, totalValue, paymentMethods} = props;
    const [paymentMethodValues, setPaymentMethodValues] = useState(paymentMethods && paymentMethods.length > 0 ? paymentMethods :  [{paymentMethod:{id:undefined}, value:totalValue , dueDate: getOnlyDate(new Date()) }]);
    const [typedTotalValue, setTypedTotalValue] = useState(0);
    const [paymentMethods_, setPaymentMethods_] = useState([]);

    useEffect(() => {
        getAllPaymentMethods().then( (p) => setPaymentMethods_(p));
    }, []);

    function handlePaymentMethodSelected(index, id) {

        if(id) {
            const paymentMethod = paymentMethods_.find((p) => p.id === Number(id));
            setPaymentMethodValues((prevItems) =>
                prevItems.map((item, i) =>
                    i === index ? {
                        ...item,
                        paymentMethod: paymentMethod,
                        splits: paymentMethod.allowSplit ? 1 : 0
                    } : item
                )
            );
        }
    }

    function handleValueChange(index, value) {
        setPaymentMethodValues((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    value: parseFloat(value)
                } : item
            )
        );


    }

    useEffect(() => {
        let typedTotalValue = 0;
        paymentMethodValues.forEach((p) => typedTotalValue += p.value);
        setTypedTotalValue(typedTotalValue);

        if(typedTotalValue > 0 && typedTotalValue < totalValue && paymentMethodValues[paymentMethodValues.length-1].value > 0) {
            setPaymentMethodValues((prevItems) =>[...prevItems, {paymentMethod:{id:undefined}, value: 0, dueDate: getOnlyDate(new Date()) }]);
        }

        if(typedTotalValue === totalValue) {
            setPaymentMethodValues((prevItems) =>
                prevItems.filter((item, i) => item.value > 0
                )
            );
        }

    }, [paymentMethodValues]);

    function handleSplitsChange(index, value) {
        setPaymentMethodValues((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    splits: Number(value)
                } : item
            )
        );
    }

    function handleConfirm() {
        if(totalValue > typedTotalValue) {
            alert(`Total informado (${typedTotalValue}) é menor que o total do aluguel (${totalValue}).`);
            return;
        }

        if(paymentMethodValues.find((p) => p.value > 0 && (p.paymentMethod === undefined || p.paymentMethod.id === undefined))) {
            alert(`Informe uma forma de pagamento.`);
            return;
        }

        onConfirm(paymentMethodValues.filter((p) => p.value > 0));
    }

    async function handleDueDateItemChange(index, date) {

        setPaymentMethodValues((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    dueDate:
                        getOnlyDate(new Date(date))
                } : item
            )
        );
    }

    return (
        <ModalDiv closeModal={onClose} style_={{width: '570px'}}>
            <div className="header-label">Formas de Pagamento</div>
            <div className="select-list-container">
                <table style={{width: '565px'}}>
                    <th>
                        Forma de Pagamento
                    </th>
                    <th>
                        Vencimento
                    </th>
                    <th>
                        Valor
                    </th>
                    {
                        paymentMethodValues.find((p) => p.paymentMethod.allowSplit === true) &&
                        <th>
                            Parcelas
                        </th>
                    }
                    <tbody>

                    {paymentMethodValues.map((paymentMethodValue, index) => (

                        <tr>
                            <td>
                                <select value={paymentMethodValue.paymentMethod.id}
                                        onChange={(e) => handlePaymentMethodSelected(index, e.target.value)}>
                                    <option value="">Selecione uma Forma de Pagamento</option>
                                    {paymentMethods_.map((paymentMethod, index) => (
                                        <option key={index} value={paymentMethod.id}>
                                            {paymentMethod.name}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td>
                                <DatePicker
                                    className="datepicker"
                                    selected={adjustDateToServerTimeZone(new Date(paymentMethodValue.dueDate))}
                                    onChange={(date) => handleDueDateItemChange(index, date)}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </td>
                            <td>
                                <input key={paymentMethodValue.id}
                                       style={{width: '80px'}}
                                       type="number" step="0.01" placeholder="0.00"
                                       value={paymentMethodValue.value}
                                       onChange={(e) => handleValueChange(index, e.target.value)}/>
                            </td>
                            {paymentMethodValue.paymentMethod.allowSplit && (
                                <td>

                                    <input type="number"
                                           style={{width: '80px'}}
                                           step="1" placeholder="1"
                                           value={paymentMethodValue.splits}
                                           onChange={(e) => handleSplitsChange(index, e.target.value)}/>

                                </td>
                            )}
                        </tr>
                    ))}

                    </tbody>
                </table>
            </div>
            <ActionButtonsManagerView>
                <ConfirmButton onClick={handleConfirm}/>
            </ActionButtonsManagerView>
        </ModalDiv>
    );
};

export default PaymentMethodValueSelect;
