import React, {useCallback, useEffect, useState} from 'react';
import CloseButton from "./CloseButton";

const ModalDiv = ({children, closeModal, style_}) => {


    const handleKeyPress = useCallback((event) => {
        if (event.key === 'Escape') {
            closeModal();
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    return (
        <div className="overlay">
            <div className="select-list-modal"
                 style={{border: '1px solid #ccc', paddingTop: '65px', padding: '20px', margin: '20px', ...style_}}>
                <CloseButton onClose={closeModal}/>
                <div style={{marginTop: '40px'}}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ModalDiv;
