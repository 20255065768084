import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import Config from "../../core/config";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import ConfirmButton from "../common/ConfirmButton";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {findSectorsByQueryString} from "../../utils/requestUtils";
import {searchCEP} from "../../core/Cep";
import {
    adjustDateToBrowserTimeZone,
    brazilianStates,
    renderSaleOptions,
    SaleStatus,
    statusTranslate
} from "../../utils/utils";
import PaymentMethodValueSelect from "../paymentMehod/PaymentMethodValueSelect";
import DatePicker from "react-datepicker";

const apiUrl = Config.apiUrl;

const SaleManager = () => {
    const [, setLoading] = useRecoilState(loadingAtom);
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');

    const newEntity = {
        id: undefined,
        description: '',
        customerName: '',
        customerPhone: '',
        customerEmail: '',
        paymentMethods: [],
        address: '',
        addressNumber: '',
        addressComplement: '',
        neighborhood: '',
        cep: '',
        city: '',
        state: '',
        value: 0.0,
        shippingCost: 0.0,
        totalValue: 0.0,
        status: 'OPENING',
        productionArea: 'LOCAL',
        deliveryType: 'LOCAL',
        details: '',
        saleDate: '',
        deliveryDate: new Date(),
        sector: undefined,
    };

    const [formData, setFormData] = useState(newEntity);
    const [totalValue, setTotalValue] = useState(Number(formData.value) + Number(formData.shippingCost));
    const [sectors, setSectors] = useState([]);
    const [showPaymentMethodValueSelect, setShowPaymentMethodValueSelect] = useState(false);

    useEffect(() => {
        let totalValue = 0.0;
        if(formData.deliveryType === 'DELIVERY') {
            totalValue += Number(formData.shippingCost);
        }
        totalValue += Number(formData.value);
        setTotalValue(totalValue);
    }, [formData]);
    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    async function makeQuery() {
        if (id) {
            try {
                const url = apiUrl + '/api/sales/id=' + id;
                const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
                response.deliveryDate = adjustDateToBrowserTimeZone(new Date(response.deliveryDate));
                setFormData(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            setFormData(newEntity);
        }
    }

    useEffect(() => {
        findSectorsByQueryString("").then(sectors => setSectors(sectors));
        makeQuery();
    }, [id]);

    function clearEntity() {
        setFormData(newEntity);
    }

    const onConfirm = async () => {
        try {
            if (validateInputs()) {
                if(totalValue > 0) {
                    openPaymentMethodValueSelect();
                } else {
                    confirmSale([]);
                }
            }
        } catch (error) {
            console.error('Error saving:', error);
            alert('Error ao salvar.');
        } finally {
            setLoading(false);
        }
    };

    const confirmSale = async (paymentMethodValues) => {
        // setLoading(true);
        await new Promise(resolve => setTimeout(resolve, 300));
        await saveEntity(paymentMethodValues);
        navigate('/salelist');
    }

    function validateInputs() {
        if (!formData.customerName || formData.customerName === '') {
            alert('Preencha o campo nome.');
            return false;
        }

        if (!formData.customerPhone || formData.customerPhone === '') {
            alert('Preencha o campo telefone.');
            return false;
        }


        if (!formData.sector) {
            alert('Preencha o campo Setor.');
            return false;
        }

        if (!formData.productionArea) {
            alert('Preencha o campo Área de Produção.');
            return false;
        }

        /* eslint-disable no-restricted-globals */
        if ((formData.value === null || formData.value === 0) && !confirm('O valor está zerado. Deseja realmente continuar?')) {
            return false;
        }

        return true;
    }

    async function saveEntity(paymentMethodValues) {
        const userUUID = Cookies.get('userUUID');
        const url = apiUrl + '/api/sales';
        if(formData.deliveryType === 'LOCAL') {
            formData.address = '';
            formData.addressNumber = 0;
            formData.addressComplement = '';
            formData.neighborhood = '';
            formData.cep = '';
            formData.city = '';
            formData.state = '';
            formData.shippingCost = 0.0;
        }
        formData.paymentMethods = paymentMethodValues;

            await makeRequest(url, id ? 'PUT' : 'POST', {
            'Content-Type': 'application/json',
            'Useruuid': userUUID,
        }, JSON.stringify(formData));
    }

    function handleStateChange(idState) {
        setFormData((prevData) => ({
            ...prevData,
            state: idState,
        }));
    }

    function handleSectorSelected(value) {
        if (value) {
            const sector = sectors.find((p) => p.id === Number(value));
            setFormData((prevData) => ({
                ...prevData,
                sector: sector
            }));
        }
    }

    function handleStatusSelected(value) {
        if (value) {
            setFormData((prevData) => ({
                ...prevData,
                status: value
            }));
        }
    }    
    async function searchCepAndFillAddressInputs() {

        let result = undefined;
        // setLoading(true);
        try {
            result = await searchCEP(formData.cep);
        } finally {
            // setLoading(false);
        }
        if (result != null) {
            setFormData((prevData) => ({
                ...prevData,
                address: result.street,
                neighborhood: result.neighborhood,
                city: result.city,
                state: result.state,
            }));
        } else {
            alert('CEP não encontrado.');
        }
    }

    const openPaymentMethodValueSelect = () => {
        setShowPaymentMethodValueSelect(true);
    };

    const closePaymentMethodValueSelect = () => {
        setShowPaymentMethodValueSelect(false);
    };

    const handleDeliveryDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            deliveryDate: date
        }))
    };

    return (
        <div>
            {showPaymentMethodValueSelect && (
                <PaymentMethodValueSelect onItemClick={confirmSale} onClose={closePaymentMethodValueSelect} paymentMethods={formData.paymentMethods}
                                    totalValue={totalValue} onConfirm={(paymentMethodValues) => {
                    closePaymentMethodValueSelect();
                    confirmSale(paymentMethodValues);
                }}/>)}

            <div className="header-label">{formData.id > 0 ? 'Editar' : 'Cadastrar'} Serviço</div>

            <div style={{display: 'flex'}}>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Cliente</div>
                    <input type="text" style={{width: '300px'}} value={formData.customerName} name="customerName"
                           onChange={handleChange}/>
                </div>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Telefone</div>
                    <input type="text" style={{width: '300px'}} value={formData.customerPhone} name="customerPhone"
                           onChange={handleChange}/>
                </div>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Email</div>
                    <input type="email" style={{width: '300px'}} value={formData.customerEmail} name="customerEmail"
                           onChange={handleChange}/>
                </div>
            </div>
            <div style={{display: 'flex'}}>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Status</div>
                    <select value={formData.status} onChange={(e) => handleStatusSelected(e.target.value)}>
                        {renderSaleOptions()}
                    </select>
                </div>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Data de Entrega:</div>
                    <DatePicker
                        className="datepicker-time"
                        selected={formData.deliveryDate}
                        onChange={handleDeliveryDateChange}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        dateFormat="dd/MM/yyyy HH:mm"
                        timeCaption="Hora"
                        isClearable
                    />
                </div>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Setor</div>
                    <select value={formData?.sector?.id}
                            onChange={(e) => handleSectorSelected(e.target.value)}>
                        <option value="">Selecione o Setor</option>
                        {sectors.map((sector, index) => (
                            <option key={index} value={sector.id}>
                                {sector.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Valor:</div>
                    <input type="number" style={{width: '100px'}} step="0.01" placeholder="0.00"
                           value={formData.value}
                           name="value"
                           onChange={handleChange}/>
                </div>
            </div>


            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div>Área de Produção</div>
                <select value={formData?.productionArea}
                        onChange={(e) =>
                            setFormData((prevData) => ({
                                ...prevData,
                                productionArea: e.target.value
                            }))
                        }>
                    <option value="">Selecione a Área de Produção</option>
                    <option key={'LOCAL'} value={'LOCAL'}>
                        {'Local'}
                    </option>
                    <option key={'PARTNER'} value={'PARTNER'}>
                        {'Parceiro'}
                    </option>

                </select>
            </div>

            <div style={{display: 'flex'}}>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Tipo de Entrega</div>
                    <select value={formData?.deliveryType}
                            onChange={(e) =>
                                setFormData((prevData) => ({
                                    ...prevData,
                                    deliveryType: e.target.value
                                }))
                            }>
                        <option value="">Selecione o Tipo de Entrega</option>
                        <option key={'LOCAL'} value={'LOCAL'}>
                            {'Local'}
                        </option>
                        <option key={'DELIVERY'} value={'DELIVERY'}>
                            {'Delivery'}
                        </option>

                    </select>
                </div>
                {formData.deliveryType === 'DELIVERY' && (
                    <div style={{marginTop: '5px', alignItems: 'center'}}>
                        <div>Frete:</div>
                        <input type="number" style={{width: '100px'}} step="0.01" placeholder="0.00"
                               value={formData.shippingCost}
                               name="shippingCost"
                               onChange={handleChange}/>
                    </div>
                )}
            </div>
            {formData.deliveryType === 'DELIVERY' && (
                <>
                    <div style={{display: 'flex'}}>
                        <div style={{marginTop: '5px', alignItems: 'center'}}>
                            <div style={{width: '80px'}}>CEP:</div>
                            <div>
                                <input style={{width: '80px'}} type="text" id="cep" value={formData.cep} name="cep"
                                       onChange={handleChange}/>
                                <button onClick={searchCepAndFillAddressInputs}>Buscar CEP</button>
                            </div>
                        </div>
                        <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                            <div style={{width: '180px'}}>Endereço:</div>
                            <input type="text" style={{width: '297px'}} value={formData.address} name="address"
                                   onChange={handleChange} autoComplete={false}/>
                        </div>

                        <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                            <div style={{width: '70px'}}>Número:</div>
                            <input type="number" step="1" placeholder="0" style={{width: '70px'}}
                                   value={formData.addressNumber} name="addressNumber"
                                   onChange={handleChange} autoComplete={false}/>
                        </div>

                        <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                            <div style={{width: '180px'}}>Complemento:</div>
                            <input type="text" style={{width: '310px'}} value={formData.addressComplement}
                                   name="addressComplement"
                                   onChange={handleChange} autoComplete={false}/>
                        </div>

                    </div>
                    <div style={{display: 'flex'}}>

                        <div style={{marginTop: '5px', alignItems: 'center'}}>
                            <div style={{width: '180px'}}>Bairro:</div>
                            <input type="text" style={{width: '400px'}} id="neighborhood" value={formData.neighborhood}
                                   name="neighborhood" onChange={handleChange}/>
                        </div>


                        <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                            <div style={{width: '180px'}}>Cidade:</div>
                            <input type="text" style={{width: '290px'}} id="city" value={formData.city}
                                   name="city" onChange={handleChange}/>
                        </div>


                        <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                            <div style={{width: '180px'}}>Estado:</div>
                            <select value={formData.state} onChange={(e) => handleStateChange(e.target.value)}>
                                <option value="">Selecione...</option>
                                {brazilianStates.map((estado) => (
                                    <option key={estado.sigla} value={estado.sigla}>
                                        {estado.sigla} - {estado.nome}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </>
            )}


            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div>Descrição do serviço</div>
                <textarea style={{width: '100%', height: "100px"}} value={formData.description} name="description"
                          onChange={handleChange}/>
            </div>

            <div className="total-label" style={{height: '37.6px', display: 'flex', alignItems: "center"}}>
                Valor Total: R$ {totalValue.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}
            </div>
            <ActionButtonsManagerView>
                <ConfirmButton onClick={onConfirm}/>
            </ActionButtonsManagerView>
        </div>
    )
        ;
};

export default SaleManager;
