import React from 'react';
import {BrowserRouter, Route, Router, Routes} from "react-router-dom";

import {useRecoilState} from "recoil";
import Login from "./Login";
import Loading from "./core/Loading";
import {loadingAtom} from "./atoms";
import Menu from "./Menu";
import CentralizedView from "./components/common/CentralizedView";
import SaleList from "./components/sale/SaleList";
import SectorList from "./components/sector/SectorList";
import SectorManager from "./components/sector/SectorManager";
import SaleManager from "./components/sale/SaleManager";
import PaymentMethodList from "./components/paymentMehod/PaymentMethodList";
import PaymentMethodManager from "./components/paymentMehod/PaymentMethodManager";
import ReceivableList from "./components/receivable/ReceivableList";
import ReceivableManager from "./components/receivable/ReceivableManager";


const AppRouter = (props) => {

    const {customerKeyValid} = props;
    const [currentLoading] = useRecoilState(loadingAtom);

    return (
        <BrowserRouter>
            {currentLoading && (<Loading/>)}
            <Routes>
                <Route path="/app"
                       element={<Menu><CentralizedView></CentralizedView></Menu>}/>
                <Route path="/login"
                       element={<Login/>}/>
                <Route path="/salelist"
                       element={<Menu><CentralizedView><SaleList/></CentralizedView></Menu>}/>
                <Route path="/sale"
                       element={<Menu><CentralizedView><SaleManager/></CentralizedView></Menu>}/>
                <Route path="/sectorlist"
                       element={<Menu><CentralizedView><SectorList/></CentralizedView></Menu>}/>
                <Route path="/sector"
                       element={<Menu><CentralizedView><SectorManager/></CentralizedView></Menu>}/>
                <Route path="/paymentmethodlist"
                       element={<Menu><CentralizedView><PaymentMethodList/></CentralizedView></Menu>}/>
                <Route path="/paymentmethod"
                       element={<Menu><CentralizedView><PaymentMethodManager/></CentralizedView></Menu>}/>
                <Route path="/receivablelist"
                       element={<Menu><CentralizedView><ReceivableList/></CentralizedView></Menu>}/>
                <Route path="/receivable"
                       element={<Menu><CentralizedView><ReceivableManager/></CentralizedView></Menu>}/>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRouter;

